<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Public Review Settings</h3>

	<p>Issuing agencies can open any framework for <b>public review</b> by <span v-html="link('edit_document', 'editing the framework’s document')"></span>, checking the “Open for Public Review comments” checkbox, and optionally entering a “Public Review Description” (see screenshot below).</p>
	<img srcset="/docimages/public_review_settings.png 2x" class="k-help-img">
	<p>When open for public review...</p>
	<ul>
		<li>Any visitor to the framework, <i>even if the visitor is not signed in to <span v-html="app_name"/></i>, will be able to leave a <span v-html="link('public_review_comments', 'public review comment')"></span> about the framework or any item in the framework. (Note that for “normal” [i.e. non-public-review] <span v-html="link('add_comments', 'comments')"></span>, a visitor <i>does</i> need to be signed in to add comments.)</li>
		<li style="font-weight:bold">The only people that will be able to see a public review comment are the person who left the comment*, and signed-in users who are designated as <span v-html="link('roles_framework', 'Editors or Admins')"></span> for the framework.</li>
		<li>*If the user leaving a public review comment is not signed in, the comment will be stored in their browser’s <a href="https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage" target="_blank">localStorage object</a>, meaning that the user will be able to see the comment, and edit or archive the comment if they wish, as long as they are viewing the framework from the same computer and browser where they originally left the comment, and as long as they have not cleared their localStorage object on the browser.</li>
		<li>If not signed in, a user leaving a public review comment can choose whether to enter their name or submit comments anonymously.</li>
		<li>Framework Admins and Editors will automatically see public review comments when they sign in and view the framework, <b>as long as <span v-html="link('add_comments', 'commenting is enabled')"></span></b>.</li>
		<li>Public review comments can be viewed just like “normal” comments: either in the “card” for the framework document or any framework item, or all together in a <span v-html="link('show_all_comments', 'table')"></span> by clicking the “SHOW ALL” button from any item card. In this table, the public review comments will appear alongside any other “normal” comments viewable by the user; use the filters in the table view if you wish to search or limit the table to certain comments.</li>
		<li>Feel free to add links to the “Public Review Description” setting if needed—e.g. to another web page that describes exactly what type of feedback you are asking reviewers to give.</li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	